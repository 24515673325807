import {
    sendAdminApi,
    sendDefaultApi,
    sendLogin,
    sendLogout,
    sendRefreshToken,
    sendRegister,
    sendValidateToken
} from "./apiService"
import {AuthRequest, AuthResponse, SecurityTokenStatus, UserRegistrationRequest} from "../types/SecurityTypes";
import {Dispatch} from "redux";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {createNotification, wrapError} from "./notificationService";
import {AxiosError} from "axios";
import {triggerRoutine as webSocketRoutine} from "./webSocketService";


export async function init(): Promise<void> {
    await refreshToken();
}


const ACCESS_TOKEN_KEY = 'jwt-access-token';

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function setAccessToken(token: string | null) {
    if (token) {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
    } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    }
}

export function isAuthenticated() {
    return !!getAccessToken();
}


export async function login(username: string, password: string, navigate: NavigateFunction, dispatch: Dispatch): Promise<void> {
    try {
        const authRequest: AuthRequest = {
            username: username,
            password: password
        };

        const response = await sendLogin(authRequest);
        const authResponse: AuthResponse = response.data;
        setAccessToken(authResponse.accessToken);
        await webSocketRoutine();
        navigate('/home');
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function logout(navigate: NavigateFunction, dispatch: Dispatch): Promise<void> {
    try {
        await sendLogout();
        setAccessToken(null);
        await webSocketRoutine();
        navigate('/login');
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function register(username: string, password: string, navigate: NavigateFunction, dispatch: Dispatch): Promise<void> {
    try {
        const authRequest: UserRegistrationRequest = {
            username: username,
            password: password
        };

        const response = await sendRegister(authRequest);
        const authResponse: AuthResponse = response.data;
        setAccessToken(authResponse.accessToken);
        await webSocketRoutine();
        navigate('/home');
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function validateToken(): Promise<SecurityTokenStatus> {
    return sendValidateToken()
        .then(response => {
            if (response.status === 200) {
                return 'OK';
            }
            return Promise.reject(new Error('Unknown response received:' + response));
        });
}

export async function refreshToken(): Promise<SecurityTokenStatus> {
    return sendRefreshToken()
        .then(response => {
            if (response.status === 200 && response.data && response.data.accessToken) {
                console.log("JWT-tokens have been refreshed");
                let accessToken: string = response.data.accessToken;
                setAccessToken(accessToken);
                return 'OK';
            } else
            return Promise.reject(new Error('Unknown response received:' + response));
        })
        .catch((error: AxiosError) => {
            setAccessToken(null);
            if (error.response && error.response.status === 401) {
                return 'EXPIRED';
            }
            return Promise.reject(new Error('Unable to refresh JWT-tokens:' + error));
        })
}


export async function apiDefault(dispatch: Dispatch): Promise<void> {
    try {
        await sendDefaultApi();
        createNotification("info", "Default API works!", dispatch);
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function apiAdmin(dispatch: Dispatch): Promise<void> {
    try {
        await sendAdminApi();
        createNotification("info", "Admin API works!", dispatch);
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}