import {init as initHttpService} from "./httpService"
import {init as initSecurityService} from "./securityService"
import {init as initWebSocketService} from "./webSocketService"

export async function init(): Promise<void> {
    await initHttpService();
    await initSecurityService();
    await initWebSocketService();
}

export default init;