import axios from 'axios';
import {getEnvVariable} from "./environmentService";
import {getAccessToken, refreshToken} from "./securityService";

const API_HOST = getEnvVariable('REACT_APP_BACKEND_HOST');
const API_SECURED = getEnvVariable('REACT_APP_BACKEND_SECURED') === 'true';
const API_HTTP_PORT = getEnvVariable('REACT_APP_BACKEND_HTTP_PORT');
const API_HTTP_URL = `${API_SECURED ? 'https://' : 'http://'}${API_HOST}:${API_HTTP_PORT}`;


export async function init(): Promise<void> {
    console.log(`API_HOST = ${API_HOST}`);
    console.log(`API_SECURED = ${API_SECURED}`);
    console.log(`API_HTTP_PORT = ${API_HTTP_PORT}`);
    console.log(`API_HTTP_URL = ${API_HTTP_URL}`);
}


const http = axios.create({
    baseURL: API_HTTP_URL,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true
});

http.interceptors.request.use(config => {
    const token = getAccessToken()
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

http.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;
    if (originalRequest.url.includes("/api/v1/auth/")) {
        return Promise.reject(error);
    }
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        return refreshToken()
            .then(refreshStatus => {
                if (refreshStatus === 'OK') {
                    let accessToken = getAccessToken();
                    if (originalRequest.headers) {
                        http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                        return http(originalRequest);
                    }
                    return Promise.reject(new Error('Authorization headers are missing'));
                }
                return Promise.reject(new Error('Session is expired'));
            });
    }
    return Promise.reject(error);
});

export default http;
