import {Client, IMessage, StompSubscription} from '@stomp/stompjs';
import {getEnvVariable} from './environmentService';
import {getAccessToken, isAuthenticated, validateToken} from "./securityService";

const API_HOST = getEnvVariable('REACT_APP_BACKEND_HOST');
const API_SECURED = getEnvVariable('REACT_APP_BACKEND_SECURED') === 'true';
const API_WS_PORT = getEnvVariable('REACT_APP_BACKEND_WS_PORT');
const API_WS_URL = `${API_SECURED ? 'wss://' : 'ws://'}${API_HOST}:${API_WS_PORT}/ws`;

const WEB_SOCKET_ROUTINE_PERIOD_MS = 5_000;
let stompClient: Client | null = null;
let isRoutineRunning = false;


export async function init(): Promise<void> {
    await routine(true);
}

export async function triggerRoutine(): Promise<void> {
    await routine(false);
}

export function sendMessage(destination: string, body: string): void {
    if (stompClient && stompClient.connected) {
        stompClient.publish({
            destination: destination,
            body: body
        });
        console.debug(`Message sent to ${destination}: ${body}`);
    } else {
        console.error('Cannot send message: WebSocket is not connected.');
    }
}

export function subscribeToTopic(destination: string, callback: (message: IMessage) => void): StompSubscription {
    if (stompClient && stompClient.connected) {
        const subscription = stompClient.subscribe(destination, callback);
        console.debug(`Subscribed to ${destination}`);
        return subscription;
    } else {
        throw new Error('Cannot subscribe: WebSocket is not connected.');
    }
}


async function routine(repeat: boolean): Promise<void> {
    if (isRoutineRunning) {
        return;
    }
    isRoutineRunning = true;

    try {
        const authenticated = isAuthenticated();
        if (authenticated) {
            if (!stompClient || !stompClient.connected) {
                await validateToken();
                const accessToken = getAccessToken();
                if (!accessToken) {
                    throw new Error('No access token');
                }
                await connect(accessToken);
            } else {
                console.debug("Keep WebSocket connection...");
            }
        } else {
            if (stompClient && stompClient.connected) {
                await disconnect();
            }
        }
    } catch (error) {
        console.error("Websocket Routine was failed!", error);
    } finally {
        isRoutineRunning = false;
        if (repeat) {
            setTimeout(
                () => routine(true),
                WEB_SOCKET_ROUTINE_PERIOD_MS
            );
        }
    }
}

async function connect(accessToken: string): Promise<void> {
    console.debug('Creating WebSocket connection ...');
    stompClient = new Client({
        brokerURL: `${API_WS_URL}?accessToken=${accessToken}`,
        reconnectDelay: 0,
        onConnect: () => {
            console.log('WebSocket is connected');
        },
        onDisconnect: () => {
            console.log('WebSocket is disconnected');
            routine(false);
        },
        onStompError: (frame) => {
            console.error('Broker reported error: ', frame.headers['message']);
            console.error('Additional details: ', frame.body);
        }
    });
    stompClient.activate();
}

async function disconnect(): Promise<void> {
    console.debug('Closing WebSocket connection ...');
    if (stompClient) {
        stompClient.deactivate()
            .then(() => {
                stompClient = null;
                console.log('WebSocket connection closed');
            });
    }
}
